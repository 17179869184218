
.wrapper{
    position: relative;
    margin-top: 0;
  }


  .SetGeneralView{
  }

  .globaldiv{
    position: relative;

  }


  .floatingleft{
    float: left;
    text-align: left;
    width:40%;
  }
  .floatingright{
    float: right;
    text-align: left;
    width:60%;
  }

  .content-text{
    font-size:15px;
    color:grey;
  }

  .content-text-ellipsis{
    font-size:16px;
    color:grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid white;
  }

   .arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-bottom: 20px solid white;
  }

  .loading {
    background-color:#44D4FF;
    filter: blur(1px);
    z-index: 25;
  }

.hidden {
    visibility: hidden;
  }
/*
  .cYyqko {
    visibility: hidden;
  }
*/

.blue-border {
    border: 2px solid  #44D4FF;
    color : #44D4FF;
    border-radius: 35px;

  }

.menu-text{
    font-size:14px;
    color:black;
    text-align: center;
  }

  .twitter{
    float: left;
    text-align: left;
    width:19%;
  }
  .welcome{
    width:800px;
    font-size:18px;color:grey;margin:auto;text-align: justify;
  }

  .btn-disabled{
    width:40%;
    cursor:default
  }

  .btn-disabled:disabled{
    background-color: transparent;
    color:white;
    width:40%;
    cursor:default
  }

.main-title{
    color:#292e37;
    font-size:41px;
    font-weight:bold;
    font-family: Baskerville, serif;
    display:inline;
  }

.second-title{
    color:#292e37;
    font-size:36px;
    font-weight:bold;
    font-family: Baskerville, serif;
    display:inline;
    font-weight: 300;
  }

  .new-content-text{
    font-size:20px;
    font-family:Arial , serif;
  }

  .sub-titles-text{
   color:#292e37;
   font-size:31px;
   font-weight:bold;
   font-family:Baskerville , serif;
   display:inline;
  }

.App {
}

.DatasetTypeIcon {
  display: inline;
  margin-right: 4px;
}

.DatasetDescriptionName {
  display: inline;
  font-size: 24px;
}

.DivSpacingDatasetDescription {
  margin-top: 2%;
}

.DatasetDescriptionValue {
  display: inline;
}

.DatasetDescriptionProperty {
  display: inline;
  color: #44D4FF;
  font-size: 20px;
}


.SearchBarFilter {
  pointer-events: all;

}

.CheckboxFilter {
  margin-right: 10px;
}

.GroupFilteringList {
  display: inline;
  width: 100%;
}

.GroupFilterText {
  margin-left: 10px;
  font-size: 18px;
    font-weight: bold;
  width: 100%;
}

.GroupFilterText:after {
  border-bottom: 2px dotted;
}

.StudyFilterText {
  margin-left: 40px;
  font-size: 16px;
}

.StudyFilterText:after {
  border-bottom: 2px dotted;
}

.FilteringPanel {
  height: 80%;
  resize: none;
  display: inline;
  overflow: scroll;
}

.SortingPanel {
  opacity: 1;
  background-color: #ffffff;
    float:right;
}

@media (prefers-reduced-motion: no-preference) {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
